import React from "react"

export default function () {
  return (
    <div id="form">
      <form
        action="https://getform.io/f/928f70fa-7066-42b5-998e-9ad107950952"
        method="POST"
      >
        <div className="flex flex-col gap-4">
          <h2>Kontakta oss</h2>
          <label for="namn">
            Namn:
            <input
              type="text"
              name="namn"
              className="border-primary border block w-full p-4 mt-2 my-2 rounded-none"
            />
          </label>

          <label for="email">
            Email:
            <input
              name="email"
              type="email"
              required=""
              className="border-primary border block w-full p-4 mt-2 my-2 rounded-none"
            />
          </label>
          <label for="telefon">
            Telefon:
            <input
              type="tel"
              name="telefon"
              required=""
              className="border-primary border block w-full p-4 mt-2 my-2 rounded-none"
            />
          </label>
          <label for="orsak">
            Beskriv varför du önskar kontakt:
            <textarea
              name="orsak"
              rows="8"
              className="border-primary border block w-full p-4 mt-2 my-2 rounded-none"
            ></textarea>
          </label>
          <div>
            <button className="bg-primary text-white uppercase font-bold lg:text-xl px-3 py-2 lg:px-6 lg:py-3 mt-4 rounded-md inline">
              Skicka
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
