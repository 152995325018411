import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Personal() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 87 }) {
        id
        medarbetare {
          personer {
            namn
            presentation
            bildPersonal {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 0.7)
                }
              }
            }
          }
        }
      }
    }
  `)

  const persons = data.wpPage.medarbetare.personer

  return (
    <div className="flex flex-col gap-4 lg:gap-8">
      {persons.map(person => {
        return <Person key={person.id} person={person} />
      })}
    </div>
  )
}

function Person({ person }) {
  const { namn, presentation, bildPersonal } = person
  return (
    <div className="flex flex-col md:flex-row gap-4 lg:gap-8">
      <div className=" w-full md:w-1/3">
        <GatsbyImage
          image={getImage(bildPersonal?.localFile.childImageSharp)}
          className="w-full "
        />
      </div>
      <div className="w-full md:w-2/3 flex flex-col justify-center">
        <h3 className="font-bold">{namn}</h3>
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: presentation }}
            className="wp-content"
          />
        </div>
      </div>
    </div>
  )
}
