import React from "react"
import ContactForm from "./ContactForm"

import FeaturedImage from "./FeaturedImage"
import PageHeader from "./PageHeader"
import Personal from "../omoss/Personal"

export default function SinglePage({ data }) {
  // React.useEffect(() => {

  //   var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]']

  //   var fitVids = document.querySelectorAll(players.join(","))

  //   if (fitVids.length) {

  //     for (var i = 0; i < fitVids.length; i++) {

  //       var fitVid = fitVids[i]
  //       var width = fitVid.getAttribute("width")
  //       var height = fitVid.getAttribute("height")
  //       var aspectRatio = height / width
  //       var parentDiv = fitVid.parentNode

  //       var div = document.createElement("div")
  //       div.className = "fitVids-wrapper"
  //       div.style.paddingBottom = aspectRatio * 100 + "%"
  //       parentDiv.insertBefore(div, fitVid)
  //       fitVid.remove()
  //       div.appendChild(fitVid)

  //       fitVid.removeAttribute("height")
  //       fitVid.removeAttribute("width")
  //     }
  //   }
  // }, [])

  const { content, title, featuredImage, parentDatabaseId, databaseId } =
    data.wpPage
  return (
    <div className="wp-page  min-h-[80vh] h-full relative pt-20 lg:pt-24">
      <div className="h-full w-full ">
        {featuredImage && (
          <div className="h-[33vh] lg:h-[50vh] fixed lg:top-24 w-full">
            <div className="relative h-full">
              <FeaturedImage image={featuredImage} />
            </div>
            <div className="absolute top-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50 ">
              <div className="max-w-4xl mx-auto lg:w-full px-4 lg:px-8">
                <h1 className=" text-2xl lg:text-5xl text-white w-full font-bold ">
                  <span>{title}</span>
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="relative bg-secondary">
        <div
          className={`${
            featuredImage ? "mt-[33vh] lg:mt-[50vh]" : ""
          } px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-16 max-w-4xl mx-auto`}
        >
          {/* <PageHeader text={title} /> */}
          <div className="flex flex-col gap-8 lg:gap-16">
            {/* Primary column */}
            <div className="flex-1 overflow-hidden space-y-8">
              <div>
                <div
                  className="wp-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>{" "}
            </div>
            {databaseId === 87 && <Personal />}
            {databaseId === 100 && <ContactForm />}
            {/* <div className="fitVids-wrapper hidden">
              <iframe />
            </div>*/}
          </div>
        </div>
        <div className="wp-content">
          <blockquote></blockquote>
        </div>
      </div>
    </div>
  )
}
